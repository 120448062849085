<template>
  <div>
    <v-dialog width="700" @click:outside="close" hide-overlay v-model="dialog">
      <v-card>
        <BarTitleDialog :title="title" @close="close" />
        <v-card-text>
          <v-scrollbar style="max-height: 600px;">
            <div v-if="logs.length == 0">
              <v-row>
                <v-col cols="12" class="text-center">
                  <svg fill="none" height="80" viewBox="0 0 20 20" width="80" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 15H12.5C12.7761 15 13 14.7761 13 14.5V13H9V15ZM9.12132 16L10.5607 17.4393C10.7244 17.6031 10.8424 17.7953 10.9146 18H14.5C15.3284 18 16 17.3284 16 16.5V8H11.5C10.6716 8 10 7.32843 10 6.5V2H5.5C4.67157 2 4 2.67157 4 3.5V9.02746C4.16417 9.00932 4.331 9 4.5 9C5.19472 9 5.85267 9.15743 6.44012 9.43856C6.71151 9.16757 7.08618 9 7.5 9H12.5C13.3284 9 14 9.67157 14 10.5V14.5C14 15.3284 13.3284 16 12.5 16H9.12132ZM13 12V10.5C13 10.2239 12.7761 10 12.5 10H9V12H13ZM11 2.25V6.5C11 6.77614 11.2239 7 11.5 7H15.75L11 2.25ZM7.30319 15.5961C7.7408 15.0118 8 14.2862 8 13.5C8 11.567 6.433 10 4.5 10C2.567 10 1 11.567 1 13.5C1 15.433 2.567 17 4.5 17C5.28617 17 6.01179 16.7408 6.59608 16.3032L9.14644 18.8536C9.3417 19.0489 9.65828 19.0489 9.85355 18.8536C10.0488 18.6584 10.0488 18.3418 9.85356 18.1465L7.30319 15.5961ZM7 13.5C7 14.8807 5.88071 16 4.5 16C3.11929 16 2 14.8807 2 13.5C2 12.1193 3.11929 11 4.5 11C5.88071 11 7 12.1193 7 13.5Z"
                      fill="#34b1ff" />
                  </svg>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <p>Nenhum log encontrado</p>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <template v-for="(log, index) in logs">
                <v-container class="mt-2" style="border: 1px solid #e0e0e0;">
                  <v-row no-gutters>
                    <v-col>
                      <span><b>Valor alterado: </b> {{ log.attribute }} </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <span><b>De: </b> {{ log.old_value }} <b>para: </b>{{ log.new_value }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <span><b>Alterado por: </b> {{ log.user.name }} </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <span><b>Data: </b> {{ log.date }} </span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </div>
          </v-scrollbar>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import logs from '@/store/log/logs';


export default {
  components: {
    BarTitleDialog,

  },

  props: {
    logs: {
      type: Object,
    }, //recebe os dados do hospede
    title: {
      type: String,
    },
    dialog: {
      type: Boolean,
    },
  },
  data: () => ({

  }),

  watch: {

  },

  created() {

  },

  computed: {

  },

  methods: {
    close() {
      this.dialog = false
    },
  },
}
</script>

<style scoped></style>